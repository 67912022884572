// var jquery = require("jquery");
// window.$ = window.jQuery = jquery; 
let captcha = '';
var verifyCallback = function (response) {
    captcha = response;
}

function onloadCallback() {
//     grecaptcha.render('html_element', {
//    'sitekey' : '6Lcb0TIhAAAAAKCPsPRfWVOGssxY7Sds7PZDD5Mz',
//    'callback' : verifyCallback,
//     });

    grecaptcha.render('html_element_modal', {
   'sitekey' : '6Lcb0TIhAAAAAKCPsPRfWVOGssxY7Sds7PZDD5Mz',
   'callback' : verifyCallback,
 });
}


$(document).ready( function () {

    $('#phone').inputmask(['(99) 9999-9999', '(99) 99999-9999']);
    $('#phoneModal').inputmask(['(99) 9999-9999', '(99) 99999-9999']);
    
    function objectifyForm(formArray) {
        //serialize data function
        var returnArray = {};
        for (var i = 0; i < formArray.length; i++){
            returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
        return returnArray;
    }

    $('#myModal').on('shown.bs.modal', function () {
        $('#myInput').trigger('focus')
    })
    
    // $("#contactForm").submit(function (e) {
    //     e.preventDefault();
    //     e.stopPropagation();
        
        
    //     var form = objectifyForm($('#contactForm').serializeArray())
    //     var $inputs = $('#myForm :input');
        
    //     // Pega os dados do Formulário
    //     var realestate = form.realestate;
    //     var name_owner = form.name_owner;
    //     var city = form.city;
    //     var neighborhood = form.neighborhood;
    //     var system = form.system;
    //     var website = form.website;
    //     var phone = form.phone;
    //     var email = form.email;
        
        
    //     // Cria um objeto com os dados
    //     var dados = {
    //         realestate,
    //         name_owner,
    //         city,
    //         neighborhood,
    //         system,
    //         website,
    //         phone,
    //         email,
    //         "g-recaptcha-response": captcha
    //     };
    //     $.ajax({
    //         url: "https://owl.net.br/post.php",
    //         type: "POST",
    //         data: JSON.stringify(dados),
    //     contentType: "application/json",
    //         success: function (response) {
    //             alert(JSON.parse(response)['message']);
    //         },
    //         error: function (xhr, status, error) {
    //             console.log("Erro na requisição:", JSON.parse(response));
    //         }
    //     });
            
    // });
    
    $("#contactFormModal").on("submit", function (e) {
        e.preventDefault()
        e.stopPropagation();
        $('#myModal').modal('hide');

        var form = objectifyForm($('#contactFormModal').serializeArray())
        var $inputs = $('#myForm :input'); 
        
        // Pega os dados do Formul?rio
        var realestate = form.realestate;
        // var name_owner = form.name_owner;
        var city = form.city;
        // var neighborhood = form.neighborhood;
        // var system = form.system;
        var website = form.website;
        var phone = form.phone;
        var email = form.email;
        
        
        // Cria um objeto com os dados
        var dados = {
            realestate,
            // name_owner,
            city,
            // neighborhood,
            // system,
            website,
            phone,
            email,
            "g-recaptcha-response": captcha
        };
        // Faz a Requisi??o POST para a API
        $.ajax({
            url: "https://owl.net.br/post.php",
            type: "POST",
            data: JSON.stringify(dados),
            contentType: "application/json",
            success: function (response) {
                console.log("🚀 ~ response:", response)
                alert(JSON.parse(response)['message']);
            },
            error: function (xhr, status, error) {
                console.log("Erro na Requisição:", JSON.parse(error));
            }
        });
    });
});
